import React from "react";

const InconCME = ({ SML, onClick }) => {
  return (
    <div onClick={onClick} className="iconContainer" style={{ cursor: "pointer" }}>
      <SML className="svgLinkedin" />
    </div>
  );
};

export default InconCME;
