import React, { useState, useEffect } from "react";
import IconP from "./IconP";
import { ReactComponent as SvgIc } from "../../images/calender-svgrepo-com.svg";
import { ReactComponent as SvgDiamong } from "../../images/diamond-svgrepo-com.svg";

const ModalProjects = ({ isOpen, onClose, project }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false); 
  const images = project && project.images ? project.images.map(img => `/${img}`) : [];

  useEffect(() => {
    if (isOpen) {
      setCurrentImageIndex(0);
    }
  }, [isOpen, project]);

  if (!isOpen || !project) return null;

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const toggleExpand = () => setIsExpanded(!isExpanded); 

  const maxLength = 100;

  return (
    <div className="modalProject">
      <div className="contantModalProject">
        <div className="contentBtnCloseATitle">
          <button onClick={onClose} className="closeButton">Cerrar</button>
          <h1 className="">{project.title}</h1>

          <div className="textPCM">
            <div className="dateContainerP">
              <IconP SvgIconC={SvgIc} />
              <p className="fechaProject">{project.dateOfCreate}</p>
            </div>
            <div className="stackContainerP">
              <IconP SvgIconC={SvgDiamong} />
              <p className="stackProject">{project.stack}</p>
            </div>
          </div>
        </div>
        
        <div className="carrousellConteiner">
          {images.length > 0 ? (
            <div className="carousel">
              <button onClick={goToPreviousImage} className="carouselButton">❮</button>
              <div className="carouselImagesContainer">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`Imagen ${index + 1}`}
                    className={`carouselImage ${
                      index === currentImageIndex ? "active" : "inactive"
                    }`}
                  />
                ))}
              </div>
              <button onClick={goToNextImage} className="carouselButton">❯</button>

            </div>
          ) : (
            <p className="pNIS">Imagenes no disponibles, porfolio en construccion</p>
          )}
        </div>

        <div className="projectDContainer">
          <h3>Descripción</h3>
          <p className="projectDescription">
            {isExpanded ? project.description : `${project.description.slice(0, maxLength)}...`}
          </p>
          <button onClick={toggleExpand} className="readMoreButton">
            {isExpanded ? "Leer menos" : "Leer más"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalProjects;
