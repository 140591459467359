import React, { useState } from "react";
import Modal from "./modalEducation";
import imgRobles from '../../images/LogoRobles.png';
import imgFreeCodecamp from '../../images/freeCodecamp.webp';
import dataCamp from '../../images/datacamp.webp';
import googleIcon from '../../images/googleIcon.webp';

import certInter from '../../images/datacampInter.webp';
import datacampIntrod from '../../images/datacampIntrod.webp';
import googleCloud from '../../images/googleCloudComp.webp';
import googleWeb from '../../images/googleWeb.webp';

const Education = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState("");
  const [modalTitle, setModalTitle] = useState(""); // Nuevo estado para el título.

  const openModal = (imgSrc, title) => {
    setModalImgSrc(imgSrc);
    setModalTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImgSrc("");
    setModalTitle("");
  };

  return (
    <article className="educationArticle">
      <h2>Educación</h2>
      <div className="educationContainerText">
        <div className="edBox">
          <div className="imgEducation">
            <img src={imgRobles} alt="Logo Robles" />
          </div>
          <div className="textEducation">
            <h3>Técnico Superior en Análisis de Sistemas</h3>
            <p>Paula Robles, Dolores.</p>
          </div>
        </div>
        <div className="edBox">
          <div className="imgEducation">
            <img src={imgFreeCodecamp} alt="freeCodeCamp" />
          </div>
          <div className="textEducation">
            <h3>Back End Development and APIs</h3>
            <p>freeCodeCamp</p>
          </div>
        </div>
        <div className="edBox">
          <div className="imgEducation">
            <img src={dataCamp} alt="DataCamp" />
          </div>
          <div className="textEducation">
            <h3>Intermediate SQL</h3>
            <p>DataCamp</p>
            <p>ID de la credencial: 37,079,451</p>
            <button
              className="verCredencial"
              onClick={() => openModal(certInter, "Intermediate SQL")}
            >
              Mostrar Credencial
            </button>
          </div>
        </div>
        <div className="edBox">
          <div className="imgEducation">
            <img src={dataCamp} alt="DataCamp" />
          </div>
          <div className="textEducation">
            <h3>Introduction to SQL</h3>
            <p>DataCamp</p>
            <p>ID de la credencial: 31,460,029</p>
            <button
              className="verCredencial"
              onClick={() => openModal(datacampIntrod, "Introduction to SQL")}
            >
              Mostrar Credencial
            </button>
          </div>
        </div>
        <div className="edBox">
          <div className="imgEducation">
            <img src={googleIcon} alt="DataCamp" />
          </div>
          <div className="textEducation">
            <h3>Cloud Computing</h3>
            <p>Google</p>
            <p>ID de la credencial: 344415958</p>
            <button
              className="verCredencial"
              onClick={() => openModal(googleCloud, "Cloud Computing")}
            >
              Mostrar Credencial
            </button>
          </div>
        </div>
        <div className="edBox">
          <div className="imgEducation">
            <img src={googleIcon} alt="DataCamp" />
          </div>
          <div className="textEducation">
            <h3>Introduction to Web Development ||</h3>
            <p>Google</p>
            <p>ID de la credencial: LFA KY7 84N</p>
            <button
              className="verCredencial"
              onClick={() => openModal(googleWeb, "Introduction to Web Development ||")}
            >
              Mostrar Credencial
            </button>
          </div>
              
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imgSrc={modalImgSrc} title={modalTitle} />
    </article>
  );
};

export default Education;
